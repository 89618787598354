.container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summaryItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.summaryValue {
  color: blue;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.bank-info {
  margin-top: 1rem;
  background-color: #e9f7ff;
  padding: 1rem;
  border: 1px solid #b3e5fc;
  border-radius: 4px;
}

.bank-info p {
  margin: 0.5rem 0;
  color: #007bff;
  font-weight: bold;
}

.bankTransferBankName {
  color: #007bff;
}

.bankTransferAccountName {
  color: #007bff;
}

.bankTransferBankCode {
  color: #007bff;
}

.bankTransferAccountNumber {
  color: #007bff;
}

/* New styles for the order summary */
.order-summary-card {
  background-color: #d5f5e3;
  padding: 1rem;
  margin-top: 0.5rem;
}
