/* SystemSetting.module.css */

/* 基本樣式 */
.systemSettingContainer {
    display: flex;
    width: 100vw; /* 使用視窗寬度 */
    margin: 0px; /* 移除水平居中 */
    background: #f9f9f9;
}

.systemSettingContainer h2, .systemSettingContainer h3 {
    text-align: center;
}

.formGroup {
    margin-bottom: 10px;
}

.formGroup label {
    margin-bottom: 5px;
}

.formGroup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.saveButton {
    display: inline-block;
    margin-right: 10px;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton:hover {
    background: #0056b3;
}

.editButton {
    background: #007bff;
}

.editButton:hover {
    background: #0056b3;
}

.deleteButton {
    background: #dc3545;
}

.deleteButton:hover {
    background: #c82333;
}

.required {
    color: red;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table, .table th, .table td {
    border: 1px solid #ddd;
}

.table th, .table td {
    padding: 1px;
    text-align: center;
}

/* 選單樣式 */
.menu {
    background-color: #e8ecee; /* 設置選單背景色為深色 */
    padding: 10px;
    border-radius: 5px;
}

.menu .nav-link {
    color: white !important; /* 設置選單連結文字顏色為白色 */
}

.menu .nav-link:hover {
    color: #cccccc !important; /* 設置懸停時的文字顏色 */
}

/* 選中項目樣式 */
.active {
    background-color: #198754 !important; /* 你可以选择你喜欢的颜色 */
    color: white !important; /* 文字颜色改为白色以更好地对比 */
    border-color: #198754 !important; /* 边框颜色与背景一致 */
}

/* 內容區域 */
.sectionContainer {
    min-height: 500px; /* 確保內容區域有足夠的高度 */
}
