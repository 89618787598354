h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.product-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

input[type="text"],
input[type="number"],
input[type="datetime-local"],
textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="datetime-local"]:focus,
textarea:focus {
  border-color: #007bff;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px dashed #cccccc;
  border-radius: 10px;
  background: #f1f1f1;
  cursor: pointer;
  transition: background 0.3s ease;
}

.dropzone:hover {
  background: #e9e9e9;
}

.image-preview {
  margin-top: 20px;
}

.image-preview h2 {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.image-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.image-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.image-info {
  display: flex;
  flex-direction: column;
}

.image-info span {
  font-weight: bold;
  color: #555;
}

.image-info button {
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  background: #e74c3c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.image-info button:hover {
  background: #c0392b;
}

.submit-button {
  padding: 15px 20px;
  font-size: 18px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 20px;
}

.submit-button:hover {
  background: #0056b3;
}
