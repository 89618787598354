/* thumbnails 容器 */
.thumbnails-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

/* thumbnail 樣式 */
.thumbnail {
    max-width: 80px;
    max-height: 80px;
    margin: 5px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.thumbnail:hover {
    border: 2px solid #333;
    transform: scale(1.1);
}

/* 產品圖片容器樣式 */
.product-image-container {
    max-width: 100%;
    margin: 0 auto;
}

.product-image-container img {
    max-width: 50%;
    height: auto;
}

/* 產品圖片輪播按鈕樣式 */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 150px;
    width: 150px;
    background-size: 100%, 100%;
    border-radius: 50%;
    background-image: none;
}

.carousel-control-next-icon:after {
    content: '>';
    font-size: 40px;
    color: #c41414;
}

.carousel-control-prev-icon:after {
    content: '<';
    font-size: 40px;
    color: #c41414;
}

/* 價格框樣式 */
.price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-weight: 1000;
    font-size: 24px;
    color: #244e7e;
}

/* 導航標籤樣式 */
.nav-link {
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 16px;
}

.nav-link.active {
    font-size: 18px;
    border-bottom: 2px solid red;
}

.nav-link:hover {
    background-color: #f0f0f0;
}

/* Tab 內容區域樣式 */
.ProductDetail-description {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.ProductDetail-title {
    color: blue;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.ProductDetail-additionalInfo {
    display: block;
    width: 100%;
}

.Tab-content {
    width: 100%;
    margin: 0 auto;
}

/* 調整 col-12 內的元素寬度 */
.col-12 {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* QuantityBtn 容器樣式 */
.quantity-container {
    padding-left: 10px; /* 讓兩個區塊之間有一些空隙 */
}
