/* ListProducts.css */

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.search-box {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: flex-start; /* 向左對齊 */
    margin-bottom: 20px;
}

.batch-delete-button {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.batch-delete-button:hover {
    background-color: #c82333;
}

.product-table {
    width: 100%;
    border-collapse: collapse;
}

.product-table th, .product-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
}

.product-table th {
    background-color: #f8f9fa;
}

.edit-button, .delete-button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
}

.edit-button:hover {
    background-color: #0056b3;
}

.delete-button {
    background-color: #dc3545;
}

.delete-button:hover {
    background-color: #c82333;
}

.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    padding: 10px 15px;
    border-radius: 4px;
    color: #007bff;
    text-decoration: none;
}

.pagination li a:hover {
    background-color: #92c5fc;
    color: white;
}

.pagination .active a {
    /* background-color: #007bff; */
    color: white;

}
