.footer {
    background-color: #343a40;
    color: #ffffff;
    padding: 20px 0;
  }
  
  .footer h5 {
    color: #ffc107;
    margin-bottom: 20px;
  }
  
  .footer p, .footer ul, .footer li {
    color: #ffffff;
  }
  
  .footer ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .footer .social-icons a {
    color: #ffffff;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer .social-icons a:hover {
    color: #ffc107;
  }
  
  .footer .text-muted {
    color: #6c757d !important;
  }
  
  .footer .line-qrcode {
    width: 75px; /* 調整這裡來縮小QR Code的大小 */
    height: auto;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .footer .row {
      text-align: center;
    }
  
    .footer .col-md-3 {
      margin-bottom: 20px;
    }
  }
  