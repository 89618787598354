/* 基本樣式 */
.categoryManagementContainer {
    padding: 20px;
    background: #f9f9f9;
}

.formGroup {
    margin-bottom: 10px;
}

.formGroup label {
    margin-bottom: 5px;
}

.formGroup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.saveButton {
    display: inline-block;
    margin-top: 10px;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton:hover {
    background: #0056b3;
}

.editButton {
    background: #007bff;
    margin-right: 5px;
}

.editButton:hover {
    background: #0056b3;
}

.deleteButton {
    background: #dc3545;
}

.deleteButton:hover {
    background: #c82333;
}

.categoryList {
    margin-top: 20px;
}

.category {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    background: white;
}

.header {
    background: #e9ecef;
    font-weight: bold;
}

.subCategory {
    padding-left: 20px;
    border-left: 2px solid #ddd;
    margin-top: 5px;
}
