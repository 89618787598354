.productName {
    font-size: 14px; /* 根据需要调整字体大小 */
    font-family: "Noto Sans TC", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.producPrice {
    font-size: 14px; /* 根据需要调整字体大小 */
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: larger;
}

.containerItem {
    background-color: #f0f0f0;  /* 设置背景色 */
    padding: 10px;  /* 可选，添加一些内边距 */
    border-radius: 20px;  /* 可选，添加圆角 */
}

.pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border: 0px solid #ddd;
    border-radius: 0px;
}

.pagination li a:hover {
    background-color: #ddd;
}

.pagination .active a {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}
