/* NotFound.css */
.not-found-container {
    text-align: center;
    padding: 50px;
    color: #333;
  }
  
  .not-found-title {
    font-size: 6rem;
    margin-bottom: 20px;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .not-found-link {
    color: #007bff;
    text-decoration: none;
    font-size: 1.2rem;
    border: 1px solid #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  