.image-preview {
  margin-top: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.image-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-item img {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
}

.image-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.image-info span {
  display: flex;
  align-items: center;
}

.image-info button, .image-info i {
  margin-top: 5px;
  cursor: pointer;
}

/* 覆寫 react-image-lightbox 的樣式 */
.react-image-lightbox .ril-image-current {
  max-width: 50%;
  max-height: 50%;
  width: auto;
  height: auto;
  object-fit: contain; /* 確保圖片保持原比例 */
}

/* 調整 lightbox 的 container 來支持原始圖片大小 */
.react-image-lightbox .ril-outer {
  display: flex;
  align-items: center;
  justify-content: center;

}

/* 覆寫 lightbox 的背景樣式 */
.react-image-lightbox .ril-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.ql-container {
  min-height: 200px !important;
}