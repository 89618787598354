.container {
    margin: 0 auto;
}

.custom-nav-dropdown .dropdown-item:hover {
    background-color: transparent; /* Set the hover background color to transparent */
    width: 100%; /* Ensure the hover background color takes the full width */
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  }

  .modal-90w {
    max-width: 90% !important;
}
