.tabs {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 20px; /* 增加按鈕之間的間距 */
    position: relative; /* 確保 badge 相對於父容器定位 */
}

/* .caret {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    display: inline-block;
} */

.caret::after {
    content: '\25BC';  /* 實心向下箭頭 */
    /* 或者使用 '\25BE' 空心向下箭頭 */
}

.tabs button {
    padding: 10px 20px;
    border: none;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    position: relative; /* 確保 badge 相對於按鈕定位 */
}

.tabs button.active {
    background: #007bff;
    color: #fff;
}

.position-absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}

.start-100 {
    left: 100%;
}

.translate-middle {
    transform: translate(-50%, -50%);
}

.badge {
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 12px;
    background-color: #6c757d;
    color: white;
}

.search-bar {
    margin-bottom: 20px;
    width: 70%; /* 設置寬度為 70% */
    margin: 0 auto; /* 使其水平居中 */
}

.input-group .form-control {
    flex: 1;
}

.input-group .btn {
    background-color: #007bff;
    color: white;
    border: none;
    transition: background-color 0.3s;
}

.input-group .btn:hover {
    background-color: #0056b3;
}

.order-list {
    margin-top: 20px;
    width: 70%; /* 設置寬度為 70% */
    margin: 0 auto; /* 使其水平居中 */
}

.order-block {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background: #f8f9fa; /* 修改背景色為淡灰色 */
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加陰影 */
}

.order-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.order-header.highlighted {
    background-color: #343a40;
    color: white;
    padding: 10px;
    border-radius: 8px;
}

.order-recipient {
    margin: 10px 0;
    padding: 10px;
    background: #e9ecef;
    border-radius: 8px;
}

.order-recipient div {
    font-size: 16px;
    margin-bottom: 5px;
}

.order-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
}

.order-item:first-child {
    border-top: none;
}

.product-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
}

.order-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.product-name {
    color: #343a40;
    font-size: 16px;
    font-weight: bold;
}

.product-details {
    display: flex;
    justify-content: space-between;
}

.subtotal {
    font-size: 14px;
    font-weight: bold;
    color: #007bff;
    text-align: right;
    margin-top: 10px;
}

.no-orders {
    text-align: center;
    font-size: 18px;
    color: #999;
}

.order-discount {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
}

.order-discount-b {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
}

.order-total {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
}

.order-shipping {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
}

.order-final {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
}

.order-total-amount {
    color: #007bff;
}

.left-align-amount {
    color: #007bff;
}

.left-align-color {
    color: #007bff;
}

.order-discount-amount {
    color: #007bff;
}